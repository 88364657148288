
const images = {
    outside1: require('images/photo/outside1.JPG'),
    outside2: require('images/photo/outside2.JPG'),
    outside4: require('images/photo/outside4.JPG'),
    outside5: require('images/photo/outside5.JPG'),

    inside1: require('images/photo/inside1.JPG'),
    inside1_1: require('images/photo/inside1_1.JPG'),
    inside1_2: require('images/photo/inside1_2.JPG'),
    inside1_3: require('images/photo/inside1_3.JPG'),
    inside1_4: require('images/photo/inside1_4.JPG'),
    inside1_5: require('images/photo/inside1_5.JPG'),

    inside2: require('images/photo/inside2.JPG'),
    inside2_1: require('images/photo/inside2_1.JPG'),
    inside2_2: require('images/photo/inside2_2.JPG'),
    inside2_3: require('images/photo/inside2_3.JPG'),
    inside2_4: require('images/photo/inside2_4.JPG'),
    inside2_5: require('images/photo/inside2_5.JPG'),
}

export default images