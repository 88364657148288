import React, { useState, useEffect } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Rnd } from "react-rnd";
import { motion } from "framer-motion";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
  Container,
  Content2Xl,
  ContentWithVerticalPadding,
  ContentWithPaddingXl
} from "components/misc/Layouts";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro";
import { LogoLink } from "components/headers/light.js";
import {
  SectionHeading as HeadingBase,
  Subheading
} from "components/misc/Headings";
import { SectionDescription as DescriptionBase } from "components/misc/Typography";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";

import MiniCenteredFooter from "components/footers/MiniCenteredFooter.js";

import { ReactComponent as CheckboxIcon } from "feather-icons/dist/icons/check-circle.svg";
import { ReactComponent as RadioIcon } from "feather-icons/dist/icons/radio.svg";
import { ReactComponent as HandleIcon } from "images/handle-icon.svg";
import { ReactComponent as ArrowRightIcon } from "images/arrow-right-3-icon.svg";

import logo from "images/logo.svg";
import useInView from "helpers/useInView";

import BackgroundAsImage from "components/hero/BackgroundAsImage.js";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import images from "images/photo/index.js";

/* Hero */
const Row = tw.div`flex`;
const NavRow = tw(
  Row
)`flex flex-col lg:flex-row items-center justify-between py-3`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-900 border-gray-400 hocus:border-gray-700 text-xl`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-primary-500 px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0 text-xl!`;
const HeroRow = tw(
  Row
)`flex-col lg:flex-row justify-between items-center pt-8 lg:pt-12 max-w-screen-2xl mx-auto flex-wrap`;

const Column = tw.div`flex-1`;

const UpdateNotice = tw(
  Column
)`w-full flex-auto mb-4 sm:mb-8 rounded px-4 py-3 sm:px-5 sm:py-4 bg-orange-100 text-orange-800 flex items-center sm:items-start md:items-center justify-center lg:justify-start border border-orange-200 text-xs sm:text-sm text-center sm:text-left md:leading-none`;
const UpdateNoticeIcon = tw(RadioIcon)`w-0 sm:w-5 sm:mr-3`;

const Description = tw(
  DescriptionBase
)`mt-4 text-center lg:text-left lg:text-base text-gray-700 max-w-lg mx-auto lg:mx-0`;
const Actions = tw.div`flex flex-col sm:flex-row justify-center lg:justify-start`;
const ActionButton = tw(
  AnchorLink
)`px-8 py-3 font-bold rounded bg-primary-500 text-gray-100 hocus:bg-primary-700 hocus:text-gray-200 focus:shadow-outline focus:outline-none transition duration-300 mt-12 inline-block tracking-wide text-center px-10 py-4 font-semibold tracking-normal`;
const PrimaryButton = tw(ActionButton)``;
const SecondaryButton = tw(
  ActionButton
)`mt-6 sm:mt-12 sm:ml-8 bg-gray-300 text-gray-800 hocus:bg-gray-400 hocus:text-gray-900`;
const FeatureList = tw.ul`mt-6 leading-loose flex flex-wrap max-w-xl mx-auto lg:mx-0`;
const Feature = tw.li`mt-2 flex items-center flex-shrink-0 w-full sm:w-1/2 justify-center lg:justify-start`;
const FeatureIcon = tw(CheckboxIcon)`w-5 h-5 text-primary-500`;
const FeatureText = tw.p`ml-2 font-medium text-gray-700`;
const ImageColumn = tw(Column)`mx-auto lg:mr-0 relative mt-16 lg:mt-0 lg:ml-8`;
const ImageContainer = tw.div``;
const Image = tw.img`max-w-full rounded-t sm:rounded`;

const SectionContainer = tw(ContentWithVerticalPadding)``;
const SectionHeading = tw(HeadingBase)`text-primary-900`;
const SectionDescription = tw(
  DescriptionBase
)`text-center mx-auto text-gray-600 max-w-4xl`;

const PreviewCards = tw.div`flex flex-wrap -mr-12`;
const PreviewCardContainer = tw.div`mt-24 mx-auto md:mx-0 max-w-lg w-full md:w-1/2 lg:w-1/3 pr-12`;
const PreviewCard = tw(motion.a)`block rounded-lg shadow-raised`;
const PreviewCardImageContainer = tw.div`rounded-t-lg border-0 border-b-0`;
const PreviewCardImage = styled(motion.div)`
  ${props => css`background-image: url("${props.$imageSrc}");`}
  ${tw`h-128 md:h-144 bg-cover bg-left-top`}
`;
const PreviewButton = tw(
  PrimaryButtonBase
)`w-full rounded-b-lg rounded-t-none py-5 font-semibold`;

const ComponentsContainer = tw.div`mt-24`;
const ComponentsType = tw.h3`text-4xl font-black text-primary-500 border-b-4 border-primary-500 inline-block`;
const Components = tw.div``;
const Component = tw.div`mt-12 border rounded-lg bg-white`;
const ComponentHeading = tw.div`px-8 py-5 border-b flex flex-col sm:flex-row justify-between items-center`;
const ComponentName = tw.h6`text-lg`;
const ComponentPreviewLink = tw.a`mt-4 sm:mt-0 text-primary-500 hocus:text-primary-900 transition duration-300 font-semibold flex items-center`;
const ComponentContent = tw.div`flex justify-between overflow-hidden rounded-b-lg bg-gray-600 relative`;
const ResizableBox = styled(Rnd)`
  ${tw`relative! bg-white pr-4`}
  .resizeHandleWrapper > div {
    ${tw`w-4! right-0!`}
  }
`;
const ResizeHandleButton = tw.button`cursor-col-resize focus:outline-none w-4 border-l bg-gray-100 absolute right-0 inset-y-0`;

const TwoColumn = tw.div`px-4 flex justify-between items-center flex-col lg:flex-row`;
const LeftColumn = tw.div`flex flex-col items-center lg:block`;
const RightColumn = tw.div`flex flex-col items-center lg:block lg:pl-8`; //tw.div`w-full sm:w-5/6 lg:w-1/2 mt-16 lg:mt-0 lg:pl-8`;

const TextColumn = tw(
  Column
)`mx-auto lg:mr-0 max-w-2xl lg:max-w-xl xl:max-w-2xl flex-shrink-0`;
const Heading = tw(
  HeadingBase
)`text-center lg:text-left text-primary-900 leading-snug`;

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Container tw="bg-gray-100 -mx-8 -mt-8 pt-8 px-8">
        <Content2Xl>
          <NavRow>
            <LogoLink>Vila Călin Sinaia</LogoLink>
            <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
              <NavLink>
                Adresa: Sinaia, Cartierul Cumpatu, Str. Alunis 18
              </NavLink>
              <NavLink>contact.vilacalin@gmail.com</NavLink>
              <PrimaryNavLink>0770166860</PrimaryNavLink>
            </div>
          </NavRow>
          <Carousel autoPlay infiniteLoop interval={5000}>
            <div>
              <img src={images.outside2} />
            </div>
            <div>
              <img src={images.outside1} />
            </div>
            <div>
              <img src={images.outside4} />
            </div>
            <div>
              <img src={images.outside5} />
            </div>
            <div>
              <img src={images.inside1} />
            </div>
            <div>
              <img src={images.inside1_1} />
            </div>
            <div>
              <img src={images.inside1_2} />
            </div>
            <div>
              <img src={images.inside2} />
            </div>
            <div>
              <img src={images.inside2_1} />
            </div>
            <div>
              <img src={images.inside2_2} />
            </div>
          </Carousel>
          <SectionContainer id="apartments">
            <SectionHeading>Apartament 1 - Parter</SectionHeading>
            <SectionDescription>
              Minim 2 nopti - 550 lei/noapte* + 75 lei/noapte paturi copil{" "}
            </SectionDescription>
            <SectionDescription>
              Compus din 2 dormitoare cu pat matrimonial, un dormitor pentru 2
              persoane cu paturi suprapuse pereferabil copiilor cu varsta de
              peste 6 ani, o toaleta de serviciu si una cu cabina de dus.
              Livingul are semineu si bucatarie complet utilata. Se poate
              inchiria pentru 2 camere si in caz de folosire a paturilor
              suprapuse este o taxa suplimentara.
              <br />
              <br />
              + Taxa de statiune 2% + Taxa salvamont(per nr de persoane)
              <br />
              <br />
            </SectionDescription>
            <Carousel>
              <div>
                <img src={images.inside1} />
              </div>
              <div>
                <img src={images.inside1_1} />
              </div>
              <div>
                <img src={images.inside1_2} />
              </div>
              <div>
                <img src={images.inside1_3} />
              </div>
              <div>
                <img src={images.inside1_4} />
              </div>
              <div>
                <img src={images.inside1_5} />
              </div>
            </Carousel>
          </SectionContainer>
          <SectionContainer id="apartamente">
            <SectionHeading>Apartament 2 - Etaj + mansarda</SectionHeading>
            <SectionDescription>
              Minim 2 nopti - 750 lei/noapte* + 75 lei/noapte paturi copil{" "}
            </SectionDescription>
            <SectionDescription>
              Compus din 3 dormitoare cu pat matrimonial. Dormitorul de la
              mansarda are si un pat pentru copil. Patul pentru copil se
              plateste suplimentar.
              <br />
              <br />
              + Taxa de statiune 2% + Taxa salvamont(per nr de persoane)
              <br />
              <br />
            </SectionDescription>
            <Carousel>
              <div>
                <img src={images.inside2} />
              </div>
              <div>
                <img src={images.inside2_1} />
              </div>
              <div>
                <img src={images.inside2_2} />
              </div>
              <div>
                <img src={images.inside2_3} />
              </div>
              <div>
                <img src={images.inside2_4} />
              </div>
              <div>
                <img src={images.inside2_5} />
              </div>
            </Carousel>
          </SectionContainer>
          <SectionContainer id="misc">
            <SectionHeading>Facilitati</SectionHeading>
            <SectionDescription>
              {" "}
              -se poate cina/comanda de la restaurant Kuib, aflat foarte aproape
              {" "}
              <br />
              -in curte exista gratar plus 2 mese cu scaune
              <br />
              {" "}
              -bucatariile sunt utilate cu plita inductie, cuptor, masina spalat
              vase, cuptor microunde, filtru cafea{" "}
            </SectionDescription>
          </SectionContainer>
        </Content2Xl>
      </Container>
      <MiniCenteredFooter />
    </AnimationRevealPage>
  );
};
